<template>
    <div class="title-render-container">
        <div class="title-back" :style="{width:width,borderBottom:borderType+' 1px #F46A17'}">
            <div class="title-success">
                <span>{{text}}</span>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'title-render',
    props: {
      width: {
        type: String,
        default: '50%'
      },
      text: {
        type: String,
        default: '为您推荐'
      },
      borderType: {
        type: String,
        default: 'solid'
      }
    }
  }
</script>

<style scoped lang="scss">
    .title-render-container {
        width: 100%;
        position: relative;

        .title-back {
            height: 14px;

            margin: 0 auto;
        }

        .title-success {
            position: absolute;
            top: 7px;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;

            span {
                background: #fff;
                color: #F46A17;
                font-size: 14px;
                font-weight: bold;
                padding: 0 10px;
            }
        }
    }
</style>